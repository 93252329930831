<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />

        <!-- Heading of the form -->
        <h3 class="sub-heading">{{ $store.getters.getTextMap().tod_billing }}</h3>

        <!-- Form -->
        <v-form :dark="$store.getters.getColorPalette().isDark" ref="form" v-model="formValid">
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{
                border:
                    '1px solid ' +
                    $store.getters.getColorPalette().panelBorderColorCode,
                'background-color':
                    $store.getters.getColorPalette().background2ColorCode,
            }" flat outlined class="px-2 my-2">
                <v-row>
                    <!-- Custom and Timeframe switching button -->
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-card-title class="sub-heading">{{ $store.getters.getTextMap().choose }}
                            {{ $store.getters.getTextMap().timeFrame }}</v-card-title>
                        <v-switch small inset style="transform: scale(0.8)" color="success" v-model="customTimeframe"
                            @change="changeTimeFrame()" :label="$store.getters.getTextMap().custom"></v-switch>
                    </v-col>
                </v-row>

                <!-- If custom timeframe is not enable -->
                <v-row no-gutters v-if="!customTimeframe">
                    <v-col align-self="center">
                        <!-- timeframe options -->
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="form.timeframe" :items="timeframeOptions"
                            :label="$store.getters.getTextMap().timeFrame" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>
                </v-row>

                <!-- Custom Timeframe Choosing Options -->
                <v-row v-else>
                    <!-- From Date -->
                    <v-col>
                        <v-menu :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor" v-model="menu2"
                            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor" v-model="from_date"
                                    :label="$store.getters.getTextMap().from_date" outlined dense
                                    prepend-icon="mdi-calendar" readonly :loading="loading" v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor" v-model="from_date"
                                @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- To Date -->
                    <v-col>
                        <v-menu :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor" v-model="menu1"
                            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    :color="$store.getters.getColorPalette().inputtextColor" v-model="to_date"
                                    :label="$store.getters.getTextMap().to_date" outlined dense
                                    prepend-icon="mdi-calendar" readonly :loading="loading" v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor" v-model="to_date"
                                @input="menu1 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card>

            <!-- Select Options if machine Groups enabled -->
            <v-card :dark="$store.getters.getColorPalette().isDark" :style="{
                border:
                    '1px solid ' +
                    $store.getters.getColorPalette().panelBorderColorCode,
                'background-color':
                    $store.getters.getColorPalette().background2ColorCode,
            }" flat outlined class="mb-2">
                <v-row>
                    <v-col cols="auto" class="d-flex" align="right" align-self="center">
                        <v-card-title class="sub-heading">{{
                            $store.getters.getTextMap().choose_machine
                            }}</v-card-title>
                        <v-switch small inset style="transform: scale(0.8)" color="success"
                            v-model="chooseFromMachineGroup"
                            :label="$store.getters.getTextMap().choose_from_machine_groups"></v-switch>
                    </v-col>
                </v-row>

                <!-- Selection Options if machine groups not enabled. -->
                <v-row no-gutters>
                    <v-col class="px-3" v-if="chooseFromMachineGroup">
                        <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="machineGroup" :items="machineGroupsOptions"
                            :label="$store.getters.getTextMap().machine_groups" dense outlined item-text="label"
                            item-value="value"></v-select>
                    </v-col>
                    <v-col v-else>
                        <WorkspaceMachineForm v-on:value="updateMachine" />
                    </v-col>
                </v-row>
            </v-card>
        </v-form>

        <!-- Selected Options Info as a table -->
        <v-card v-if="!loading && machines.length > 0" :dark="$store.getters.getColorPalette().isDark" :style="{
            border:
                '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode,
            'background-color':
                $store.getters.getColorPalette().background2ColorCode,
        }" flat outlined class="mb-2">
            <v-row no-gutters>
                <v-col>
                    <v-simple-table maxHeight="200px" :style="{
                        background: $store.getters.getColorPalette().background2ColorCode,
                    }">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <strong>{{
                                            $store.getters.getTextMap().machine_id
                                            }}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{ $store.getters.getTextMap().name }}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{ $store.getters.getTextMap().action }}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.label" v-for="(item, index) in machines">
                                    <td>{{ item.machine_id }}</td>
                                    <td>{{ item.machine_name }}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="deleteMachineFromList(item, index)"
                                            title="Delete Confirmation"
                                            description="Are you sure you want to delete this Machine?">
                                            <v-icon small color="red">mdi-delete</v-icon>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>

        <v-card :dark="$store.getters.getColorPalette().isDark" :style="{
            border:
                '1px solid ' + $store.getters.getColorPalette().panelBorderColorCode,
            'background-color':
                $store.getters.getColorPalette().background2ColorCode,
        }" flat outlined class="px-2 mb-2">
            <!--  Graph if there is data  -->
            <v-row v-if="
                showGraph &&
                form &&
                form.machines &&
                form.machines.length > 0 &&
                from_date
            ">
        
                <v-col>
                    <TODBillingBarGraph :machines="form.machines" :from_date="from_date" :to_date="to_date"
                        :group_name="groupName" />
                </v-col>
            </v-row>

            <!-- IF there is no data -->
            <v-row v-else class="py-2">
                <v-col>
                    <div :style="[{ display: 'flex', 'justify-content': 'center' }]">
                        <v-icon class="mr-3">mdi-database-remove-outline</v-icon>
                        <h3>No data to show</h3>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <!-- Button for submit and cancel -->
        <v-row no-gutters>
            <v-spacer />
            <v-col cols="12" class="d-flex justify-space-between" align="right">
                <div>
                    <v-btn :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="generate">{{
                            $store.getters.getTextMap().submit }}</v-btn>
                    <v-btn class="ml-2" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{
                            $store.getters.getTextMap().cancel }}</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
import moment from "moment";
import FormRules from "@/utillities/FormRules";
import WorkspaceMachineForm from "@/components/forms/WorkspaceMachineForm";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import TODBillingBarGraph from "@/components/graphs/TODBillingBarGraph.vue";
// import TODBillTemplate from '@/components/graphs/templates/TODBillTemplate.vue'

export default {
    name: "TODBillingAnalysisPanel",
    components: {
        InfoAlert,
        WorkspaceMachineForm,
        DeleteConfirmation,
        TODBillingBarGraph,
        // TODBillTemplate
    },
    mounted() { },
    data() {
        return {
            loading: false,
            valid: false,
            formValid: false,
            showDismissibleAlert: false,
            info: "",
            edit: false,
            menu2: false,
            menu1: false,
            chooseFromMachineGroup: false,
            customTimeframe: false,
            machineGroup: null,
            groupName: null,
            showGraph: false,
            machines: [],
            from_date: null,
            to_date: null,
            form: {
                timeframe: "mtd",
            },
            machineOptions: [],
            timeframeOptions: [
                { label: "last 7 days", value: "7" },
                { label: "last 30 days", value: "30" },
                { label: "last 60 days", value: "60" },
                { label: "last 90 days", value: "90" },
                { label: "last 180 days", value: "180" },
                { label: "last 365 days", value: "365" },
                { label: "Yesterday", value: "yesterday" },
                { label: "MTD", value: "mtd" },
                { label: "YTD", value: "ytd" },
            ],
            widthOptions: [
                { label: "Full screen", value: 12 },
                { label: "Half screen", value: 6 },
                { label: "1/3 screen", value: 4 },
                { label: "Quarter screen", value: 3 },
                { label: "3/4 screen", value: 9 },
                { label: "Auto", value: null },
            ],
            heightOptions: [
                { label: "Fixed", value: "fixed" },
                { label: "Flex", value: "flex" },
            ],
            rules: FormRules.rules,
            objectRules: [(v) => !!v || "Required"],
            numberRules: [
                (v) => !!v || "Required",
                (v) => /[0-9]+$/.test(v) || "It should be a number",
                (v) => (v && v > 0) || "It should be a valid number",
                (v) =>
                    (v && v.length <= 5 && v.length > 0) ||
                    "Name must be less than 5 digits",
            ],
        };
    },
    computed: {
        machineGroupsOptions() {
            let op = [];
            let g = this.$store.state.machineGroups;
            for (let i of g) {
                op.push({ value: i.machine_group_id, label: i.name });
            }
            return op;
        },
    },
    methods: {
        changeTimeFrame() {
            this.from_date = null;
            this.to_date = null;
        },
        updateMachine(payload) {
            if (payload && payload.machine && payload.machine.machine_id) {
                this.loading = true;
                let temp =
                    this.form && this.form.machines && this.form.machines.length > 0
                        ? this.form.machines
                        : [];
                this.form.machines = [];
                if (
                    !temp.some((obj) => obj.machine_id === payload.machine.machine_id)
                ) {
                    temp.push({
                        machine_name: payload.machine.machine_name
                            ? payload.machine.machine_name
                            : payload.machine.machine_id,
                        machine_id: payload.machine.machine_id,
                    });
                }
                this.form.machines = temp;
                this.machines = temp;
            }
            this.loading = false;
        },

        getMachineGroupByID(groupID) {
            let machine_group_list = this.$store.getters.getMachineGroups();
            let group = machine_group_list.filter(
                (item) => item.machine_group_id === groupID
            );
            this.groupName = group[0].name;
        },

        addMachinesFromMachineGroup() {
            if (this.machineGroup) {
                this.getMachineGroupByID(this.machineGroup);
                this.loading = true;
                axios
                    .post(
                        this.$store.state.api + "getTuplesByMachineGroup",
                        { machine_group_id: this.machineGroup },
                        { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
                    )
                    .then((response) => {
                        if (response.data.status == "success") {
                            let temp = response.data.data;
                            let machineList =
                                this.form && this.form.machines && this.form.machines.length > 0
                                    ? this.form.machines
                                    : [];
                            this.form.machines = [];
                            for (let i of temp) {
                                if (
                                    !machineList.some((obj) => obj.machine_id === i.machine_id)
                                ) {
                                    machineList.push({
                                        machine_name: i.label,
                                        machine_id: i.machine_id,
                                    });
                                }
                            }
                            this.form.machines = machineList;
                            this.machines = machineList;
                            // this.machineGroup=null
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.info = err;
                        this.showDismissibleAlert = true;
                    });
            }
        },
        deleteMachineFromList(item) {
            this.form.machines = this.form.machines.filter(
                (x) => x.machine_id != item.machine_id
            );
            this.machines = this.form.machines;
        },
        generate() {
            if (!this.customTimeframe) {
                this.from_date = moment().subtract(1, "days");
                switch (this.form.timeframe ? this.form.timeframe : "mtd") {
                    case "7":
                        this.from_date = moment().subtract(7, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "30":
                        this.from_date = moment().subtract(30, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "60":
                        this.from_date = moment().subtract(60, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "90":
                        this.this.from_date = moment()
                            .subtract(90, "days")
                            .format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "180":
                        this.from_date = moment().subtract(180, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "365":
                        this.from_date = moment().subtract(365, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "yesterday":
                        this.from_date = moment().subtract(1, "days").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    case "ytd":
                        this.from_date = moment().startOf("year").format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                    default:
                        this.from_date = moment()
                            .subtract(1, "days")
                            .startOf("month")
                            .format("YYYYMMDD");
                        this.to_date = moment().subtract(1, "days").format("YYYYMMDD");
                        break;
                }
            }
            this.showGraph = true;
        },
        onReset() {
            this.showGraph = false;
            this.machines = [];
            this.machineGroup = null;
            this.form = {};
            this.form = {};
            this.$refs.form.reset();
            this.$refs.todBillingForm.reset();
            this.loading = false;
        },
        onClose() {
            this.onReset();
        },
    },
    watch: {
        machineGroup() {
            this.addMachinesFromMachineGroup();
        },
        form() {
            this.generate();
        },
        from_date() {
            if (this.form && this.form.machines && this.form.machines.length > 0) {
                this.generate();
            }
        },
        to_date() {
            if (this.form && this.form.machines && this.form.machines.length > 0) {
                this.generate();
            }
        },
    },
};
</script>