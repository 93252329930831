var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('h3',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().tod_billing))]),_c('v-form',{ref:"form",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card',{staticClass:"px-2 my-2",style:({
            border:
                '1px solid ' +
                _vm.$store.getters.getColorPalette().panelBorderColorCode,
            'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto","align":"right","align-self":"center"}},[_c('v-card-title',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().choose)+" "+_vm._s(_vm.$store.getters.getTextMap().timeFrame))]),_c('v-switch',{staticStyle:{"transform":"scale(0.8)"},attrs:{"small":"","inset":"","color":"success","label":_vm.$store.getters.getTextMap().custom},on:{"change":function($event){return _vm.changeTimeFrame()}},model:{value:(_vm.customTimeframe),callback:function ($$v) {_vm.customTimeframe=$$v},expression:"customTimeframe"}})],1)],1),(!_vm.customTimeframe)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center"}},[(!_vm.loading)?_c('v-select',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"items":_vm.timeframeOptions,"label":_vm.$store.getters.getTextMap().timeFrame,"dense":"","outlined":"","item-text":"label","item-value":"value"},model:{value:(_vm.form.timeframe),callback:function ($$v) {_vm.$set(_vm.form, "timeframe", $$v)},expression:"form.timeframe"}}):_vm._e()],1)],1):_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"label":_vm.$store.getters.getTextMap().from_date,"outlined":"","dense":"","prepend-icon":"mdi-calendar","readonly":"","loading":_vm.loading},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"label":_vm.$store.getters.getTextMap().to_date,"outlined":"","dense":"","prepend-icon":"mdi-calendar","readonly":"","loading":_vm.loading},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-2",style:({
            border:
                '1px solid ' +
                _vm.$store.getters.getColorPalette().panelBorderColorCode,
            'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto","align":"right","align-self":"center"}},[_c('v-card-title',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().choose_machine))]),_c('v-switch',{staticStyle:{"transform":"scale(0.8)"},attrs:{"small":"","inset":"","color":"success","label":_vm.$store.getters.getTextMap().choose_from_machine_groups},model:{value:(_vm.chooseFromMachineGroup),callback:function ($$v) {_vm.chooseFromMachineGroup=$$v},expression:"chooseFromMachineGroup"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.chooseFromMachineGroup)?_c('v-col',{staticClass:"px-3"},[(!_vm.loading)?_c('v-select',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().inputtextColor,"menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"items":_vm.machineGroupsOptions,"label":_vm.$store.getters.getTextMap().machine_groups,"dense":"","outlined":"","item-text":"label","item-value":"value"},model:{value:(_vm.machineGroup),callback:function ($$v) {_vm.machineGroup=$$v},expression:"machineGroup"}}):_vm._e()],1):_c('v-col',[_c('WorkspaceMachineForm',{on:{"value":_vm.updateMachine}})],1)],1)],1)],1),(!_vm.loading && _vm.machines.length > 0)?_c('v-card',{staticClass:"mb-2",style:({
        border:
            '1px solid ' + _vm.$store.getters.getColorPalette().panelBorderColorCode,
        'background-color':
            _vm.$store.getters.getColorPalette().background2ColorCode,
    }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-simple-table',{style:({
                    background: _vm.$store.getters.getColorPalette().background2ColorCode,
                }),attrs:{"maxHeight":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().machine_id))])]),_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().name))])]),_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().action))])])])]),_c('tbody',_vm._l((_vm.machines),function(item,index){return _c('tr',{key:item.label},[_c('td',[_vm._v(_vm._s(item.machine_id))]),_c('td',[_vm._v(_vm._s(item.machine_name))]),_c('td',[_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this Machine?"},on:{"confirm":function($event){return _vm.deleteMachineFromList(item, index)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-delete")])],1)],1)])}),0)]},proxy:true}],null,false,2884922292)})],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"px-2 mb-2",style:({
        border:
            '1px solid ' + _vm.$store.getters.getColorPalette().panelBorderColorCode,
        'background-color':
            _vm.$store.getters.getColorPalette().background2ColorCode,
    }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[(
            _vm.showGraph &&
            _vm.form &&
            _vm.form.machines &&
            _vm.form.machines.length > 0 &&
            _vm.from_date
        )?_c('v-row',[_c('v-col',[_c('TODBillingBarGraph',{attrs:{"machines":_vm.form.machines,"from_date":_vm.from_date,"to_date":_vm.to_date,"group_name":_vm.groupName}})],1)],1):_c('v-row',{staticClass:"py-2"},[_c('v-col',[_c('div',{style:([{ display: 'flex', 'justify-content': 'center' }])},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-database-remove-outline")]),_c('h3',[_vm._v("No data to show")])],1)])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","align":"right"}},[_c('div',[_c('v-btn',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().submitbtnColor,"small":"","filled":""},on:{"click":_vm.generate}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().submit))]),_c('v-btn',{staticClass:"ml-2",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().cancelbtnColor,"small":""},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().cancel))])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }