<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
            <v-col>
                <h2>{{report && report.report_id?$store.getters.getTextMap().update_dynamic_excel_report:$store.getters.getTextMap().create_dynamic_excel_report}}</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="report && report.report_id" align="right">
                <v-chip v-if="$store.state.user && $store.state.settingMode" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-avatar
                        :dark="$store.getters.getColorPalette().isDark"
                        :style="{color: $store.getters.getColorPalette().background2ColorCode,}"
                        :class="$store.getters.getColorPalette().foregroundColorName +'mx-1 px-0'"
                    >
                        <v-icon :color="$store.getters.getColorPalette().accentCode" small>mdi-file-chart-outline</v-icon>
                    </v-avatar
                >{{report.report_id}}</v-chip>
            </v-col>
        </v-row>
        <v-form ref="metForm" v-model="metaValid">
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
                <v-card-title color="secondary" >{{ $store.getters.getTextMap().add_sheet}}</v-card-title>
                <v-row>
                    <v-col>
                        <v-text-field  
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="sheet_label"
                            dense
                            outlined
                            :counter="30"
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().sheet_name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select  
                            :dark="$store.getters.getColorPalette().isDark" 
                            v-model="sheet_id"
                            :items="types"
                            outlined
                            :label="$store.getters.getTextMap().sheet"
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :color="$store.getters.getColorPalette().inputtextColor"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="addToMeta">
                            {{ $store.getters.getTextMap().add_sheet}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
                                mdi-tab-plus                             
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
            <v-card-title color="secondary" >{{ $store.getters.getTextMap().sheets}}</v-card-title>
            <v-row>
                <v-col>
                    <v-simple-table :dark="$store.getters.getColorPalette().isDark" :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" >
                                        <strong>{{ $store.getters.getTextMap().sheet_id }}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{ $store.getters.getTextMap().name }}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{ $store.getters.getTextMap().action }}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in form.meta" :key="index">
                                    <td>{{ item.sheet_id }}</td>
                                    <td>{{ item.label }}</td>
                                    <td>
                                        <DeleteConfirmation v-on:confirm="removeFromMeta(index)"  title="Delete Confirmation"  description="Are you sure you want to delete this Sheet?">
                                            <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_sheet }}
                                                <v-icon small color="red">mdi-delete</v-icon>
                                            </v-btn>
                                        </DeleteConfirmation>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
        <v-form ref="reportForm" v-model="valid">
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="pa-3 mt-2">
                <v-row>
                    <v-col>
                        <v-card-title color="secondary" >{{report && report.report_id? $store.getters.getTextMap().edit_report_details:$store.getters.getTextMap().Create_report }}</v-card-title>
                    </v-col>
                    <v-col cols="auto" v-if="report && report.report_id">
                        <v-switch
                            small
                            inset
                            style="transform: scale(0.8);"
                            color="success"
                            v-model="form.disable"
                            :label="$store.getters.getTextMap().disable"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.name"
                            :counter="50"
                            dense
                            outlined
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().report_name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col class="ml-2">
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-model="form.notification_group"
                            :items="notificationGroupList"
                            :label="$store.getters.getTextMap().notification_groups"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            card
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col align="right">
                        <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="onSubmit">{{report && report.report_id?$store.getters.getTextMap().update_details:$store.getters.getTextMap().create_report}}
                            <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-file-chart-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'CreateDynamicExcelReport',
    props:['report'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            this.getSheetByReportId()
            this.api="updateDynamicExcelReport"
        }else{
            this.api='createDynamicExcelReport'
        }
        this.getSheets()
    },
    data(){
        return {
            showDialog:false,
            sheets:[],
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            metaValid:false,
            api:'createDynamicExcelReport',
            form:{
                meta:[],
                disable:false
            },
            metaForm:{},
            sheet_label:null,
            sheet_id:null,
            types:[

            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 50 && v.length > 0) || 'Name must be less than 50 characters',
            ],
            listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{
        reportList(){
            let op=[]
            switch(this.metaForm.type){
                case 'sec_reports':
                    for(let i of this.$store.state.secTimeFrameReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
                case 'sec_report':
                    for(let i of this.$store.state.secTimeFrameReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
                case 'usage_report':
                    for(let i of this.$store.state.usageReports){
                        op.push({label:i.name,value:i.report_id})
                    }
                    break;
            }
            return op
        },
        notificationGroupList(){
            let op=[]
            if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
                for(let i of this.$store.state.notificationGroups){
                    op.push({
                        label:i.name,
                        value:i.group_id
                    })
                }
            }
            return op
        }
    },
    methods:{
        addToMeta(){
            this.metaForm['label']=this.sheet_label
            this.metaForm['sheet_id']=this.sheet_id
            if(this.report && this.report.report_id){
                let payload = {
                    label:this.sheet_label,
                    report_id:this.report.report_id,
                    sheet_id:this.sheet_id,
                }
                this.loading=true
                axios.post(this.$store.state.api+'addSheetsToDynamicExcelReport',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.$refs.metForm.validate()
                        if(this.metaValid){
                            let payload=Object.assign({},this.metaForm)
                            this.form.meta.push(payload)
                        }
                        this.$store.dispatch('refreshDynamicExcelReports')        
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }else{
                this.$refs.metForm.validate()
                if(this.metaValid){
                    let payload=Object.assign({},this.metaForm)
                    this.form.meta.push(payload)
                }
            }
        },
        removeFromMeta(index){
            if(this.report && this.report.report_id){
                this.loading=true
                let payload = {
                    report_id:this.report.report_id,
                    sheet_id:this.form.meta[index].sheet_id
                }
                axios.post(this.$store.state.api+'deleteDynamicExcelSheetMapping',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.form.meta.splice(index,1)
                        this.$store.dispatch('refreshDynamicExcelReports')
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                    
                })
            }else{
                this.form.meta.splice(index,1)
            }
        },
        getSheets(){
            this.loading=true
            axios.get(this.$store.state.api+'sheets',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.sheets=response.data.data
                    this.getTypes()
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },
        getSheetByReportId(){
            this.loading=true
            axios.post(this.$store.state.api+'getSheetsByReportID',this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.form.meta=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        },
        getTypes(){
            let temp = {}
            for(let i of this.sheets){
                temp = {label:i['name'], value:i['sheet_id']},
                this.types.push(temp)
            }
        },
        onSubmit(){
            this.$refs.reportForm.validate()
            if(this.valid){
                if(this.report && this.report.report_id){
                    let successMessage="Report Details Updated"
                    this.loading=true
                    let payload = {
                        name: this.form.name,
                        report_id:this.report.report_id,
                        notification_group:this.form.notification_group,
                        disable:this.form.disable
                    }
                    axios.post(this.$store.state.api+this.api,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    .then(response=>{
                    if(response.data.status==='success'){
                        this.$store.dispatch('refreshDynamicExcelReports')
                        this.info=successMessage
                        this.showDismissibleAlert=true
                        this.dialog=false
                        this.$emit('close')
                    }else{
                        this.info=response.data.msg
                        this.loading=false
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                    }).catch(err=>{
                        this.info=err
                        this.loading=false
                    });
                }else{
                    if(this.form.meta && this.form.meta.length>0){
                        let successMessage="New Report Created"
                        this.loading=true
                        this.form.dynamic_excel_sheet_mapping_array = this.form.meta
                        axios.post(this.$store.state.api+this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                        .then(response=>{
                        if(response.data.status==='success'){
                            this.$store.dispatch('refreshDynamicExcelReports')
                            this.info=successMessage
                            this.showDismissibleAlert=true
                            this.dialog=false
                            this.$emit('close')
                        }else{
                            this.info=response.data.msg
                            this.loading=false
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                        })
                        .catch(err=>{
                            this.info=err
                            this.loading=false
                        });
                    }else{
                        this.loading=false
                        this.info='Sheet list is empty'
                        this.showDismissibleAlert=true
                    }
                }
            }
        },
        cancel(){
            this.showDialog=false
        },
        confirm(){
            this.showDialog=false
        }
    },
    watch:{
        sheet_id(){
            let sheet=this.types.find(obj => obj.value === this.sheet_id);
            this.sheet_label=sheet['label']
        },
    }
}
</script>
<style scoped >
.v-dialog{
    transform: scale(0.85);
    margin-bottom: 65px;
    width:150%;
}
</style>
