<template>
  <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-col v-if="showSheetType" cols="3">
        <v-card
          flat
          class="widget-card-container"
          :dark="$store.getters.getColorPalette().isDark"
          :style="{
            maxHeight: '100%',
            height: '100%',
            'background-color':
              $store.getters.getColorPalette().backgroundColorCode,
          }"
        >
          <v-subheader>{{ $store.getters.getTextMap().sheets }}</v-subheader>
          <v-divider></v-divider>
          <v-card
            flat
            class="widgetype-card-container"
            :dark="$store.getters.getColorPalette().isDark"
            :style="{
              background: $store.getters.getColorPalette().backgroundColorCode,
            }"
          >
            <v-row>
              <v-col>
                <v-list
                  :color="$store.getters.getColorPalette().backgroundColorCode"
                  dense
                >
                  <template v-for="item in types">
                    <v-list-item
                      v-if="item.label"
                      :key="item.label"
                      @click="handleSheetItemClick(item)"
                    >
                      <v-list-item-action v-if="item.condition">
                        <v-icon
                          :style="{
                            color: $store.getters.getColorPalette().accentCode,
                          }"
                          >{{ item.icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content v-if="item.condition">
                        <!-- <v-btn text outlined small> -->
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                        <!-- </v-btn> -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
      <v-col
        v-if="showSheetType"
        cols="9"
        :style="{
          borderLeft:
            '1px solid ' +
            $store.getters.getColorPalette().background2ColorCode,
        }"
      >
        <v-card
          flat
          class="widget-card-container"
          :dark="$store.getters.getColorPalette().isDark"
          :style="{
            'background-color':
              $store.getters.getColorPalette().backgroundColorCode,
          }"
        >
          <component
            :is="getSheet()"
            :sheet="sheet"
            v-on:close="close"
            v-on:update="$emit('update')"
          />
        </v-card>
      </v-col>
      <v-col v-else>
        <v-card
          flat
          outlined
          class="widget-card-container"
          :dark="$store.getters.getColorPalette().isDark"
          :style="{
            'background-color':
              $store.getters.getColorPalette().background1ColorCode,
          }"
        >
          <component
            :is="getSheet()"
            :sheet="sheet"
            v-on:close="close"
            v-on:update="$emit('update')"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import GeneralSheetForm from "@/components/forms/GeneralSheetForm";
import ProcessBasedUsageSheetForm from "@/components/forms/ProcessBasedUsageSheetForm";
import MachineTagDailySheetForm from "@/components/forms/MachineTagDailySheetForm";
import ShiftBasedUsageSheetForm from "@/components/forms/ShiftBasedUsageSheetForm";
import ProcessBasedHourlyDataSheet from "@/components/forms/ProcessBasedHourlyDataSheet";
import ConsolidatedShiftUsageDataSheet from "@/components/forms/ConsolidatedShiftUsageDataSheet";
import MachineGroupUsageSheet from "@/components/forms/MachineGroupUsageSheet.vue";
import ParameterUsageSheet from "@/components/forms/ParameterUsageSheet.vue";
import ParamGroupBasedTimeframedSheet from "@/components/forms/ParamGroupBasedTimeframedSheet.vue";
import DynamicLoggerBasedDataSheet from "@/components/forms/DynamicLoggerBasedDataSheet.vue";
import MachineShiftUsageLogBasedSheet from "@/components/forms/MachineShiftUsageLogBasedSheet.vue";
import ParamEquationBasedSheet from "@/components/forms/ParamEquationBasedSheet.vue";
import ConsumptionBasedSheet from "@/components/forms/ConsumptionBasedSheet.vue";
import StatisticalAnalysisBasedSheet from "@/components/forms/StatisticalAnalysisBasedSheet.vue";
import ParameterInstantaneousDataSheet from "@/components/forms/ParameterInstantaneousDataSheet.vue";
import SafetyIncidentSheet from "@/components/forms/SafetyIncidentSheet.vue";
import SafetyInspectionSheet from "@/components/forms/SafetyInspectionSheet.vue";
import ChecklistStatusSheet from "@/components/forms/sheets/ChecklistStatusSheet.vue";
export default {
  name: "CreateSheets",
  props: {
    sheet: {
      type: Object,
      required: true,
    },
  },
  components: {
    InfoAlert,
    GeneralSheetForm,
    ProcessBasedUsageSheetForm,
    ShiftBasedUsageSheetForm,
    ProcessBasedHourlyDataSheet,
    ConsolidatedShiftUsageDataSheet,
    MachineGroupUsageSheet,
    ParameterUsageSheet,
    ParamGroupBasedTimeframedSheet,
    DynamicLoggerBasedDataSheet,
    MachineShiftUsageLogBasedSheet,
    ParamEquationBasedSheet,
    ConsumptionBasedSheet,
    StatisticalAnalysisBasedSheet,
    ParameterInstantaneousDataSheet,
    SafetyIncidentSheet,
    SafetyInspectionSheet,
    ChecklistStatusSheet,
  },
  mounted() {
    if (this.sheet && this.sheet.sheet_type) {
      this.showSheetType = false;
      this.sheet_type = this.sheet.sheet_type;
    }
  },
  data() {
    return {
      showSheetType: true,
      sheet_type: "",
      showDismissibleAlert: false,
      loading: false,
      info: "",
      compMap: {
        general: GeneralSheetForm,
        processBased: ProcessBasedUsageSheetForm,
        machineTagDailySheet: MachineTagDailySheetForm,
        shiftBasedUsageSheet: ShiftBasedUsageSheetForm,
        processBasedHourlyDataSheet: ProcessBasedHourlyDataSheet,
        consolidatedShiftUsageDataSheet: ConsolidatedShiftUsageDataSheet,
        machineGroupBased: MachineGroupUsageSheet,
        parameterUsageSheet: ParameterUsageSheet,
        paramGroupBasedTimeframed: ParamGroupBasedTimeframedSheet,
        dynamicLoggerBasedData: DynamicLoggerBasedDataSheet,
        machineShiftUsageLogBasedSheet: MachineShiftUsageLogBasedSheet,
        paramEquationBasedSheet: ParamEquationBasedSheet,
        consumptionBasedSheet: ConsumptionBasedSheet,
        statisticalAnalysisBasedSheet: StatisticalAnalysisBasedSheet,
        parameterInstantaneousDataSheet: ParameterInstantaneousDataSheet,
        safetyIncidentSheet: SafetyIncidentSheet,
        safetyInspectionSheet: SafetyInspectionSheet,
        checklistStatusSheet: ChecklistStatusSheet,
      },
      types: [
        // {label:"General Sheet", value:'general'},
        {
          label: "Process Based Usage",
          value: "processBased",
          icon: "mdi-google-analytics",
          condition: this.$store.state.user,
        },
        {
          label: "Process Based Timeframed",
          value: "processBasedHourlyDataSheet",
          icon: "mdi-clock-time-eight-outline",
          condition: this.$store.state.user,
        },
        {
          label: "Machine Tag Based Daily",
          value: "machineTagDailySheet",
          icon: "mdi-turbine",
          condition: this.$store.state.user,
        },
        {
          label: "Machine Group Based",
          value: "machineGroupBased",
          icon: "mdi-group",
          condition: this.$store.state.user,
        },
        {
          label: "Shift Based",
          value: "shiftBasedUsageSheet",
          icon: "mdi-image-filter-tilt-shift",
          condition: this.$store.state.user,
        },
        {
          label: "Consolidated Shift Usage",
          value: "consolidatedShiftUsageDataSheet",
          icon: "mdi-consolidate",
          condition: this.$store.state.user,
        },
        {
          label: "Parameter Based",
          value: "parameterUsageSheet",
          icon: "mdi-file-chart",
          condition: this.$store.state.user,
        },
        {
          label: "Parameter Group Based Timeframed",
          value: "paramGroupBasedTimeframed",
          icon: "mdi-timetable",
          condition: this.$store.state.user,
        },
        {
          label: "Parameter Instantaneous",
          value: "parameterInstantaneousDataSheet",
          icon: "mdi-page-next-outline",
          condition: this.$store.state.user,
        },
        {
          label: "Param Equation Based",
          value: "paramEquationBasedSheet",
          icon: "mdi-plus-minus-variant",
          condition: this.$store.state.user,
        },
        {
          label: "Consumption Based",
          value: "consumptionBasedSheet",
          icon: "mdi-chart-donut",
          condition: this.$store.state.user,
        },
        {
          label: "Dyanmic Logger Based",
          value: "dynamicLoggerBasedData",
          icon: "mdi-math-log",
          condition: this.$store.state.user,
        },
        {
          label: "Machine Usage Log Based",
          value: "machineShiftUsageLogBasedSheet",
          icon: "mdi-image-filter-tilt-shift",
          condition: this.$store.state.user,
        },
        {
          label: "Statistical Analysis Based",
          value: "statisticalAnalysisBasedSheet",
          icon: "mdi-trending-up",
          condition: this.$store.state.user,
        },
        {
          label: "Safety Incident",
          value: "safetyIncidentSheet",
          icon: "mdi-text-box-outline",
          condition: this.$store.state.user,
        },
        {
          label: "Safety Inspection",
          value: "safetyInspectionSheet",
          icon: "mdi-police-badge-outline",
          condition: this.$store.state.user,
        },
        {
          label: "Checklist Status Sheet",
          value: "checklistStatusSheet",
          icon: "mdi-check-circle-outline",
          condition: this.$store.state.user,
        },
      ],
      objectRules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 25 && v.length > 0) ||
          "Name must be less than 25 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+,?/.test(v) || "E-mail must be valid",
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v >= 0 && v % 1 == 0) || "It should be a valid number",
        (v) =>
          (v && v.length <= 5 && v.length > 0) ||
          "Name must be less than 6 digits",
      ],
      nonNegativeRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v > 0 && v % 1 == 0) || "It should be a valid number",
        (v) =>
          (v && v.length <= 5 && v.length > 0) ||
          "Name must be less than 6 digits",
      ],
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    getSheet() {
      return this.compMap[this.sheet_type];
    },
    handleSheetItemClick(item) {
      this.sheet_type = item.value;
    },
  },
};
</script>
<style scoped>
.widget-card-container {
  height: 540px;
  max-height: 540px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.widgetype-card-container {
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
