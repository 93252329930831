var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('v-row',[(_vm.showSheetType)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"widget-card-container",style:({
          maxHeight: '100%',
          height: '100%',
          'background-color':
            _vm.$store.getters.getColorPalette().backgroundColorCode,
        }),attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().sheets))]),_c('v-divider'),_c('v-card',{staticClass:"widgetype-card-container",style:({
            background: _vm.$store.getters.getColorPalette().backgroundColorCode,
          }),attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-row',[_c('v-col',[_c('v-list',{attrs:{"color":_vm.$store.getters.getColorPalette().backgroundColorCode,"dense":""}},[_vm._l((_vm.types),function(item){return [(item.label)?_c('v-list-item',{key:item.label,on:{"click":function($event){return _vm.handleSheetItemClick(item)}}},[(item.condition)?_c('v-list-item-action',[_c('v-icon',{style:({
                          color: _vm.$store.getters.getColorPalette().accentCode,
                        })},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),(item.condition)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1):_vm._e()],1):_vm._e()]})],2)],1)],1)],1)],1)],1):_vm._e(),(_vm.showSheetType)?_c('v-col',{style:({
        borderLeft:
          '1px solid ' +
          _vm.$store.getters.getColorPalette().background2ColorCode,
      }),attrs:{"cols":"9"}},[_c('v-card',{staticClass:"widget-card-container",style:({
          'background-color':
            _vm.$store.getters.getColorPalette().backgroundColorCode,
        }),attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c(_vm.getSheet(),{tag:"component",attrs:{"sheet":_vm.sheet},on:{"close":_vm.close,"update":function($event){return _vm.$emit('update')}}})],1)],1):_c('v-col',[_c('v-card',{staticClass:"widget-card-container",style:({
          'background-color':
            _vm.$store.getters.getColorPalette().background1ColorCode,
        }),attrs:{"flat":"","outlined":"","dark":_vm.$store.getters.getColorPalette().isDark}},[_c(_vm.getSheet(),{tag:"component",attrs:{"sheet":_vm.sheet},on:{"close":_vm.close,"update":function($event){return _vm.$emit('update')}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }