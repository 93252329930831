<template>
  <v-container>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-form ref="generalSheetForm" v-model="generalSheetValid">
      <v-row no-gutters>
        <v-col>
          <v-card-title>{{
            $store.getters.getTextMap().checklist_status_sheet
          }}</v-card-title>
        </v-col>
      </v-row>
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="'transparent'"
        elevation="4"
        class="my-2"
        style="
          padding: 15px 15px 15px 15px;
          margin-top: 20px;
          margin-bottom: 20px;
        "
      >
        <v-row>
          <v-col>
            <v-text-field
              v-if="!loading"
              v-model="form.name"
              :rules="nameRules"
              :label="$store.getters.getTextMap().sheet_name"
              dense
              outlined
              required
              :dark="$store.getters.getColorPalette().isDark"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="form.meta.timeframe"
              :items="timeframeOptions"
              :label="$store.getters.getTextMap().time_frame"
              dense
              outlined
              item-text="label"
              item-value="value"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              required
              :dark="$store.getters.getColorPalette().isDark"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="form.meta.timestamp_type"
              :items="timestampTypeOptions"
              :label="
                $store.getters.getTextMap().timestamp +
                ' ' +
                $store.getters.getTextMap().type
              "
              dense
              outlined
              item-text="label"
              item-value="value"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              required
              :dark="$store.getters.getColorPalette().isDark"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="'transparent'"
        elevation="4"
        class="my-2"
        style="
          padding: 15px 15px 15px 15px;
          margin-top: 20px;
          margin-bottom: 20px;
        "
      >
        <v-row>
          <v-col cols="auto" class="d-flex">
            <v-col>
              <v-checkbox
                :label="
                  $store.getters.getTextMap().add +
                  ' ' +
                  $store.getters.getTextMap().all
                "
                v-model="addAllChecklist"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-select
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
                v-model="checklist_id"
                outlined
                :items="checklistOptions"
                :label="$store.getters.getTextMap().check_list"
                dense
                filled
                item-text="label"
                item-value="value"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              ></v-select>
            </v-col>
            <v-btn
              class="mt-4 mx-5"
              color="gray darken-1"
              small
              outlined
              @click="addChecklistToList"
              >{{ $store.getters.getTextMap().add_to_list }}</v-btn
            >
            <v-btn
              class="mt-4 mx-5"
              :color="$store.getters.getColorPalette().deletebtnColor"
              outlined
              small
              @click="deleteFromChecklistTable"
              >{{ $store.getters.getTextMap().cancel }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-card
              :dark="$store.getters.getColorPalette().isDark"
              :color="'transparent'"
              flat
            >
              <v-row
                v-if="!loading && checklistTableDetails"
                style="margin-top: 20px"
              >
                <v-col cols="12">
                  <v-simple-table
                    height="200px"
                    :style="{ background: 'transparent' }"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            <strong>{{
                              $store.getters.getTextMap().name
                            }}</strong>
                          </th>
                          <th class="text-left">
                            <strong>{{
                              $store.getters.getTextMap().check_list
                            }}</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="item" v-for="item in checklistTableDetails">
                          <td>{{ getChecklistNameById(item) }}</td>
                          <td>{{ item }}</td>
                          <td>
                            <DeleteConfirmation
                              v-on:confirm="deleteFromChecklistTable(item)"
                              title="Delete Confirmation"
                              description="Are you sure you want to delete this?"
                            >
                              <v-icon
                                small
                                :color="
                                  $store.getters.getColorPalette()
                                    .deletebtnColor
                                "
                                >mdi-delete</v-icon
                              >
                            </DeleteConfirmation>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col align="right">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            @click="sheetSubmit"
            >{{ $store.getters.getTextMap().submit }}</v-btn
          >
        </v-col>
        <v-col align="left">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().cancelbtnColor"
            :dark="$store.getters.getColorPalette().isDark"
            outlined
            small
            @click="close"
            >{{ $store.getters.getTextMap().cancel }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import axios from "axios";
export default {
  name: "ChecklistStatusSheet",
  props: {
    sheet: {
      type: Object,
    },
  },
  components: {
    InfoAlert,
    DeleteConfirmation,
  },
  mounted() {
    if (this.sheet && this.sheet.meta) {
      this.checklistTableDetails = this.sheet.meta.checklists;
      this.form.name = this.sheet.name;
      this.api = "updateSheets";
      this.form.sheet_id = this.sheet.sheet_id;
      this.form.meta.dataSource = this.sheet.meta.dataSource;
    }
    this.getChecklistsOptions();
    this.form.sheet_type = "checklistStatusSheet";
  },
  data() {
    return {
      showDialog: false,
      api: "createSheets",
      checklistTableDetails: [],
      checklist_id: null,
      checklistOptions: [],
      generalSheetValid: false,
      showDismissibleAlert: false,
      loading: false,
      addAllChecklist: false,
      info: "",
      dbData: [],
      timeframeOptions: [
        { label: "MTD", value: "mtd" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last 7 Days", value: "last7" },
      ],
      timestampTypeOptions: [
        { label: "Created At", value: "timestamp" },
        { label: "Submitted At", value: "submitted_timestamp" },
      ],
      form: {
        name: null,
        sheet_type: "checklistStatusSheet",
        meta: {
          checklists: [],
          timeframe: "last7",
          timestamp_type: "timestamp",
        },
      },
      objectRules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 25 && v.length > 0) ||
          "Name must be less than 25 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+,?/.test(v) || "E-mail must be valid",
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v >= 0 && v % 1 == 0) || "It should be a valid number",
        (v) =>
          (v && v.length <= 5 && v.length > 0) ||
          "Name must be less than 6 digits",
      ],
      nonNegativeRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v > 0 && v % 1 == 0) || "It should be a valid number",
        (v) =>
          (v && v.length <= 5 && v.length > 0) ||
          "Name must be less than 6 digits",
      ],
    };
  },
  methods: {
    getChecklistNameById(id) {
      let details = this.dbData.find((item) => item.checklist_id === id);
      return details && details.name ? details.name : id;
    },
    getChecklistsOptions() {
      let op = [];
      this.loading = true;
      axios
        .get(this.$store.state.api + "checklists", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.dbData = response.data.data;
            for (let i of this.dbData) {
              if (i && i.name) {
                op.push({ value: i.checklist_id, label: i.name });
              } else {
                op.push({ value: i.checklist_id, label: i.name });
              }
            }
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
      this.checklistOptions = op;
    },
    close() {
      this.$emit("close");
    },
    addChecklistToList() {
      if (!this.checklistTableDetails) {
        this.checklistTableDetails = [];
      }
      if (this.checklist_id) {
        this.checklistTableDetails.push(this.checklist_id);
      } else {
        this.loading = false;
        this.info = "Please select checklist";
        this.showDismissibleAlert = true;
      }
      this.checklist_id = null;
    },
    sheetSubmit() {
      this.$refs.generalSheetForm.validate();
      if (
        this.generalSheetValid &&
        this.checklistTableDetails &&
        this.checklistTableDetails.length > 0
      ) {
        this.loading = true;
        this.form.meta.checklists = this.checklistTableDetails;
        let d = Object.assign({}, this.form);
        axios
          .post(this.$store.state.api + this.api, d, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.loading = false;
              this.$emit("update");
              this.$store.dispatch("refreshSheets");
              this.$refs.generalSheetForm.reset();
              this.close();
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      } else {
        this.loading = false;
        this.info = "Please add checklist";
        this.showDismissibleAlert = true;
      }
    },
    addAllChecklistToList() {
      for (let i of this.dbData) {
        this.checklistTableDetails.push(i.checklist_id);
      }
    },
    removeAllChecklistFromList() {
      this.checklistTableDetails = [];
    },
    deleteFromChecklistTable(item) {
      this.checklistTableDetails = this.checklistTableDetails.filter(
        (x) => x != item
      );
    },
    onChecklistReset() {
      this.checklist_id = null;
      this.loading = false;
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
  },
  watch: {
    addAllChecklist() {
      if (this.addAllChecklist) {
        this.addAllChecklistToList();
      } else {
        this.removeAllChecklistFromList();
      }
    },
  },
};
</script>
